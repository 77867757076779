import React from 'react';
import "./Blog.css";
import img1 from "../Assets/blog1.png";
import img2 from "../Assets/blog2.png";
import icon1 from "../Assets/milk.png";
import icon2 from "../Assets/mutton.png";
import icon3 from "../Assets/carrot.png";
import icon4 from "../Assets/shalgom.png";
import icon5 from "../Assets/greenflower.png";
import icon6 from "../Assets/mushro.png";
import icon7 from "../Assets/fish.png";
import { Fade } from 'react-reveal';

const Blog = () => {
    return (
        <div className='blog-container'>
            <img src={icon1} className="blog-icon" alt="" />
            <img src={icon2} className="blog-icon right-0" alt="" />
            <img src={icon3} className="blog-icon left-0 top-[42%]" alt="" />
            <img src={icon4} className="blog-icon left-[46%] top-[41%]" alt="" />
            <img src={icon5} className="blog-icon right-0 top-[34%]" alt="" />
            <img src={icon6} className="blog-icon left-0 bottom-[20px]" alt="" />
            <img src={icon7} className="blog-icon left-[47%] bottom-[40px]" alt="" />

            <div className='blog-content'>
                <div className='blog-item'>
                    <Fade left delay={100} >
                        <div className='blog-cont-img'>
                            <div className='blogImg'>
                                <img src={img1} className="blog-img" alt="" />
                            </div>
                        </div>
                    </Fade>
                    <Fade left delay={800}>
                        <div className='blog-text'>
                            <h1 className='blog-title'>Under development</h1>
                            <p className='blog-desc'>
                                We are a new Middle Eastern shop currently under development, aiming to establish ourselves on the high street near you. If you have any product requests or suggestions, please let us know.
                                Our shop will offer a wide range of items, from basic necessities to exotic spices. Additionally, we will provide Halal meat, chicken, and other special requests, including Kofta and Kebab mixes.
                                Thank you for visiting our website. We look forward to welcoming you to our store soon.
                            </p>
                        </div>
                    </Fade>
                </div>

                <div className='blog-item'>
                    <Fade right delay={900}>
                        <div className='blog-text'>
                            <h1 className='blog-title'>Why shop here?</h1>
                            <p className='blog-desc'>
                                At our store, we pride ourselves on offering an authentic Middle Eastern shopping experience, bringing you a diverse range of high-quality products. 
                                From everyday essentials to unique and exotic spices, we cater to all your culinary needs. Our commitment to providing fresh Halal meat, chicken, and custom mixes like Kofta and Kebab ensures that you can enjoy the best traditional flavours. 
                                With a customer-focused approach, we strive to fulfil your requests and preferences, making your shopping experience convenient and enjoyable. Additionally, we offer free delivery for locals on any order over £30. Shop with us for genuine products, exceptional service, and a touch of Middle Eastern hospitality.
                            </p>
                            <div className='blog-tag'>
                                <h3>We welcome everyone!</h3>
                                <div className='blog-tags'>
                                    <div className='blog-tag-circle'></div>
                                    <div className='blog-tag-circle'></div>
                                    <div className='blog-tag-circle green'></div>
                                </div>
                            </div>
                        </div>
                    </Fade>
                    <Fade top delay={800}>
                        <div className='blog-cont-img'>
                            <div className='blogImg'>
                                <img src={img2} className="blog-img" alt="" />
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>

            <hr className='blog-hr' />
        </div>
    );
};

export default Blog;
