import React, { useState } from 'react';
import Layout from '../Component/Layout/Layout';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './products.css'; // Import the products.css file

// Importing product images
import inside1 from '../Component/ProductsPics/inside1.png';
import face from '../Component/ProductsPics/face.png';
import Meat from '../Component/ProductsPics/meat.jpg';
import ChickenRoasted from '../Component/ProductsPics/ChickenRoasted.jpg';
// import Mix from '../Component/ProductsPics/mix.jpg';
import Meats from '../Component/ProductsPics/meats.jpg';
import pickles from '../Component/ProductsPics/pickles.png';
import fridge_items from '../Component/ProductsPics/fridge_items.png';
// import Products5 from '../Component/ProductsPics/products 5.jpg';
// import Products6 from '../Component/ProductsPics/products 6.jpg';
// import Spices1 from '../Component/ProductsPics/spices1.jpg';
import spices from '../Component/ProductsPics/Spices.png';

const productImages = [
  { src: face, legend: 'Almansour Market' },
  { src: inside1, legend: 'Our products' },
  { src: Meat, legend: 'Meat we sell' },
  { src: ChickenRoasted, legend: 'Roasted Chicken' },
  { src: fridge_items, legend: 'Visit our fridge!' },
  { src: Meats, legend: 'Meat chops & more!' },
  { src: pickles, legend: 'Authentic pickles!' },
  { src: spices, legend: 'Spices, mixed nuts & more!' }
];

const Products = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleThumbnailClick = (index) => {
    setCurrentImageIndex(index);
  };

  return (
    <Layout>
      <div className="products-page">
        <div className="products-container">
          <h2 className="products-title">Our Products</h2>
          <div className="carousel-container">
            <Carousel
              autoPlay={true}
              interval={4000}
              infiniteLoop={true}
              showArrows={true}
              showStatus={false}
              showIndicators={true}
              showThumbs={false}
              selectedItem={currentImageIndex}
              onChange={setCurrentImageIndex}
            >
              {productImages.map((image, index) => (
                <div key={index}>
                  <img src={image.src} alt={`Product ${index + 1}`} className="carousel-image" />
                  <p className="legend">{image.legend}</p>
                </div>
              ))}
            </Carousel>
          </div>
          <div className="thumbnail-container">
            {productImages.map((image, index) => (
              <div
                key={index}
                className={`thumbnail ${index === currentImageIndex ? 'active' : ''}`}
                onClick={() => handleThumbnailClick(index)}
              >
                <img src={image.src} alt={`Thumbnail ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Products;
