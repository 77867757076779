import React from 'react';
import "./Header.css";
import logo from "../Assets/brand1.png";
import callicon from "../Assets/call icon.svg";
// import { BiSearchAlt, BiUser } from 'react-icons/bi';
// import { BsHeart, BsCart2 } from 'react-icons/bs';



const Header = () => {
    return (
        <div className='header-container'>

            <div className='logo-search-cont'>
                <img src={logo} alt="" />
                {/* 
                <div className='search-cont'> 
                    <BiSearchAlt className='icon' />
                    <input type="text" placeholder='Search' />
                </div>
                */}
            </div>

            <div className='contact-social-cont'>

                <div className='contact'>
                    <img src={callicon} alt="Call us" class="call-icon"/>
                    <p>Call Us <br /> <span>07853 255611</span> </p>
                </div>
                {/*  
                <div className='social-icon-cont'>
                    <div className='headerIcon'>
                        <BiUser className='headerIcon-size' />
                    </div>

                    <div className='headerIcon'>
                        <BsHeart className='headerIcon-size' />
                    </div>

                    <div className='headerIcon'>
                        <BsCart2 className='headerIcon-size' />
                    </div>
                </div>
                */}
            </div>

        </div>
    );
};

export default Header;
