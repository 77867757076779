import React from 'react';
import Layout from '../Component/Layout/Layout';
import locationImage from '../Component/Assets/location_street.png'; // Correct path to your image
import './location.css'; // Import the Location.css file

const Location = () => {
  return (
    <Layout>
      <div className="location-container">
        <h2 className="location-title">Find Us</h2>
        <p className="location-text">
          Our shop is situated near Enterprise Car Rental and between two car dealerships (see the map below). Additionally, you can easily access buses from both sides, as the bus stops are conveniently close, so there's no need to walk far.
        </p>
        <p className="location-text">
          For free parking, you can park your car on the pavement in front of the shop. If it's busy, there's ample free parking on the other side of the road (please check the road signs for more information).
        </p>
        <p className='location-text'>Our full address is: <b>32 Hampton Rd W, Feltham TW13 6AY</b></p>
        <img src={locationImage} alt="Location" className="location-image" />
        <div className="map-container">
          <iframe
            title="Shop Location"
            width="100%"
            height="600"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=32%20Hampton%20Rd%20W,%20Feltham%20TW13%206AY+(Aswaq%20Almansour%20)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          ></iframe>
        </div>
      </div>
    </Layout>
  );
};

export default Location;
