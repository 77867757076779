// Brands.js

import React from 'react';
import "./Brands.css";
import img1 from "../Assets/brand1.png";
import img2 from "../Assets/brand2.png";
import img3 from "../Assets/brand3.png";
import img4 from "../Assets/brand4.png";
import img5 from "../Assets/brand5.png";
import img6 from "../Assets/brand6.png";

const Brands = () => {
    return (
        <div className='brands-container'>
            <div className='brand-images'>
            <img src={img1} className="brandImg brandImg-large" alt="Brand 1" />
                <img src={img2} className="brandImg" alt="Brand 2" />
                <img src={img3} className="brandImg" alt="Brand 3" />
                <img src={img4} className="brandImg" alt="Brand 4" />
                <img src={img5} className="brandImg" alt="Brand 5" />
                <img src={img6} className="brandImg" alt="Brand 6" />
            </div>
        </div>
    );
};

export default Brands;
