// import React from 'react';
// import "./Navbar.css";
// // import { GiHamburgerMenu } from 'react-icons/gi';
// // import { MdKeyboardArrowDown } from 'react-icons/md';


// const Navbar = () => {
//     return (
//         <div className='navbar-container'>

//             <div className='navbar-sub-container'>
            
//                 {/* <div className='burger-menu'> 
//                     <GiHamburgerMenu className='burger-menu-icon' />
//                     <p>Sort by Department</p>
//                 </div>*/}

//                 <div className='navbar'>
//                     <ul className='anim-nav'>
//                         <li><a href="#!">Home</a></li>
//                         <li><a href="#!" > {/* <span className='flex items-center'>*/}Location{/* <MdKeyboardArrowDown className='text-[25px]' /></span>*/}</a></li>
//                         <li><a href="#!" > {/* <span className='flex items-center'>*/}Products {/* <MdKeyboardArrowDown className='text-[25px]' /></span>*/} </a></li>
//                         {/* <li><a href="#!">Blog</a></li> */}
//                         <li><a href="#!">Conatct</a></li>
//                     </ul>
//                 </div>
//             </div>

//         </div>
//     );
// };

// export default Navbar;

import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
// import { GiHamburgerMenu } from 'react-icons/gi';
// import { MdKeyboardArrowDown } from 'react-icons/md';

const Navbar = () => {
    return (
        <div className='navbar-container'>
            <div className='navbar-sub-container'>
                {/* <div className='burger-menu'> 
                    <GiHamburgerMenu className='burger-menu-icon' />
                    <p>Sort by Department</p>
                </div> */}
                <div className='navbar'>
                    <ul className='anim-nav'>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/location">Location</Link></li>
                        <li><Link to="/products">Products</Link></li>
                        {/* <li><Link to="/blog">Blog</Link></li> */}
                        <li><Link to="/contact">Contact</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
