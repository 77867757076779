import React from 'react';
import "./Footer.css";
// import logo from "../Assets/brand1.png";
import payment from "../Assets/payment.png";
import vector1 from "../Assets/Vector1.png";
import vector2 from "../Assets/Vector2.png";
import vector3 from "../Assets/Vector3.png";
import vector4 from "../Assets/Vector4.png";
import vector5 from "../Assets/Vector5.png";
import vector6 from "../Assets/Vector6.png";
import vector7 from "../Assets/Vector7.png";
import vector8 from "../Assets/Vector8.png";
import vector9 from "../Assets/Vector9.png";
import vector10 from "../Assets/Vector10.png";
import vector11 from "../Assets/Vector11.png";
import { FaFacebookF, FaTiktok } from 'react-icons/fa';
import { BsInstagram, BsTwitter } from 'react-icons/bs';
// import { MdArrowForwardIos } from 'react-icons/md';
import { Fade } from 'react-reveal';

const Footer = () => {
    return (
        <div className='footer-container'>
            <img src={vector1} className="left-[25%] top-[18%]" alt="" />
            <img src={vector2} className="left-[62%] top-[10%]" alt="" />
            <img src={vector3} className="right-[-50px] top-[20%]" alt="" />
            <img src={vector4} className="left-[-10px] top-[30%]" alt="" />
            <img src={vector5} className="left-[30%] top-[44%]" alt="" />
            <img src={vector6} className="left-[42%] top-[30%]" alt="" />
            <img src={vector7} className="left-[55%] top-[42%]" alt="" />
            <img src={vector8} className="left-[66.5%] top-[33%]" alt="" />
            <img src={vector9} className="left-[29%] top-[65%]" alt="" />
            <img src={vector10} className="left-[46%] top-[73%]" alt="" />
            <img src={vector11} className="left-[65%] top-[70%]" alt="" />
            <img src={vector6} className="right-[2%] top-[70%]" alt="" />
            <img src={vector3} className="left-[20px] bottom-[20px]" alt="" />

            <div className='footer-content'>
                <div className='flex flex-col items-center'>
                    <div className='text-center mb-4'>
                        <h3 className='text-[18px] font-[500]'>Call Us <br /> <span className='font-[600]'>+44 7853 255611</span> </h3>
                    </div>
                    <div className='text-center'>
                        <h3 className='text-[18px] font-[500]'>Follow Us</h3>
                        <div className='footer-icons'>
                            <div className='bottom-social-icon'>
                                <FaFacebookF className='text-white' />
                            </div>
                            <a href="https://www.instagram.com/aswaq_almansour/" target="_blank" rel="noopener noreferrer" className='bottom-social-icon'>
                                <BsInstagram className='text-white' />
                            </a>
                            <div className='bottom-social-icon'>
                                <BsTwitter className='text-white' />
                            </div>
                            <div className='bottom-social-icon'>
                                <FaTiktok className='text-white' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='text-center'>
                    <h3 className='text-[18px] font-[500]'>Accepted payment methods:</h3>
                    <img src={payment} alt="" />
                </div>
            </div>

            <div className='hr-line'></div>

            <div className='footer-content flex-col text-center'>
                <Fade left delay={600}>
                    <div className='footer-info'>
                        <h2>Our Address</h2>
                        <p>32 Hampton Rd W, Feltham TW13 6AY <br /> London</p>
                        <p>Phone: 07853 255611</p>
                    </div>
                </Fade>

                <Fade left delay={700}>
                    <div className='footer-info opening-times'>
                        <h2>Opening Times</h2>
                        <p>Monday 8 am–8 pm</p>
                        <p>Tuesday 8 am–8 pm</p>
                        <p>Wednesday 8 am–8 pm</p>
                        <p>Thursday 8 am–8 pm</p>
                        <p>Friday 8 am–8 pm</p>
                        <p>Saturday 9 am–9 pm</p>
                        <p>Sunday 10 am–7 pm</p>
                    </div>
                </Fade>
            </div>

            <div className='hr-line'></div>

            <p className='copyright'>© 2024 All Rights Reserved || Designed by Kayth.co.uk</p>
        </div>
    );
};

export default Footer;
