import React from 'react';
import "./TopSell.css";
import SliderTwo from '../SliderTwo/SliderTwo';

const TopSell = () => {
    return (
        <div className='mx-[320px] mt-[60px] mx-centered'>

            <div data-aos="fade-down" data-aos-duration="2000" data-aos-easing="ease-in-out" className='section-title2'>
                <div className='section-line2 mx-centered'></div>
                <h1>Latest News</h1>
                <div className='section-line2 mx-centered'></div>
            </div>

            <div data-aos="fade-left" data-aos-duration="1500" className='mt-[60px]'>
                <SliderTwo />
            </div>

            <hr className='mt-[67px]' />

        </div>
    );
};

export default TopSell;
