import React from 'react';
import "./FeatureProducts.css";
import Slider from '../Slider/Slider';

const FeatureProducts = () => {
    return (
        <div className='feature-products-container'>

            <div data-aos="fade-down" data-aos-duration="2000" data-aos-easing="ease-in-out" className='section-title'>
                <div className='section-line'></div>
                <h1>Our Products</h1>
                <div className='section-line'></div>
            </div>

            <div data-aos="fade-right" data-aos-duration="1500" className='slider-container'>
                <Slider />
            </div>

        </div>
    );
};

export default FeatureProducts;
