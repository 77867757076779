import React from 'react';
import img2 from "../Assets/dp-img2.jpg";
import img4 from "../Assets/Healthy Food.png";
import img7 from "../Assets/Fast Delivery.png";
import meat from "../Assets/meat.png";
import bread from "../Assets/bread.png";
import spices from "../Assets/spicy-food.png";
import mix from "../Assets/mix.png";
import "./DisplaySection.css";
import { TypeAnimation } from 'react-type-animation';

const DisplaySection = () => {
    return (
        <div className='container'>
            <div className='main-display'>
                <div className='display-first-section'>
                    <img src={meat} className="meat-image" alt="Fresh Meat" />
                    <div className='content-sec-one'>
                        <h2>FRESH</h2>
                        <h1>MEAT</h1>
                        <h3>
                            <TypeAnimation
                                sequence={['EVERYDAY.', 2000, '']}
                                speed={50}
                                repeat={Infinity}
                            />
                        </h3>
                    </div>
                </div>
                <div className='grid-container'>
                    <div className='relative'>
                        <div className='content-section-two'>
                            <h1>Fresh</h1>
                            <h2>Vegetables</h2>
                        </div>
                        <div className="hover05 column">
                            <div>
                                <figure>
                                    <img src={img2} alt="Fresh Vegetables" />
                                </figure>
                            </div>
                        </div>
                    </div>
                    <div className='relative'>
                        <div className='content-section-three'>
                            <h1>NEW</h1>
                            <h2>PRODUCTS</h2>
                        </div>
                        <div className="hover05 column">
                            <div>
                                <figure>
                                    <img src={mix} alt="New Products" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='sub-section'>
                <div className='sub-section-cont'>
                    <div className='main-section'>
                        <img src={img4} className="icon" alt="Quality Products" />
                        <div>
                            <h2>Quality products</h2>
                            <p>Anything from Vegetables<br /> to meat and spices</p>
                        </div>
                    </div>
                </div>
                <div className='sub-section-cont'>
                    <div className='main-section'>
                        <img src={bread} className="icon" alt="Fresh Bread" />
                        <div>
                            <h2>Fresh Bread</h2>
                            <p>Both Arabic<br /> & Afghan bread </p>
                        </div>
                    </div>
                </div>
                <div className='sub-section-cont'>
                    <div className='main-section'>
                        <img src={spices} className="icon" alt="100% Natural Spices" />
                        <div>
                            <h2>100% Natural</h2>
                            <p>Middle Eastern <br /> ingredients </p>
                        </div>
                    </div>
                </div>
                <div className='sub-section-cont'>
                    <div className='main-section'>
                        <img src={img7} className="icon" alt="Free Delivery" />
                        <div>
                            <h2>Free Delivery locally</h2>
                            <p>Free delivery for locals <br /> for any order over £30 </p>
                        </div>
                    </div>
                </div>
            </div>
            <hr className='separator' />
        </div>
    );
};

export default DisplaySection;
