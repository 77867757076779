// import React from 'react';
// import { useEffect } from 'react';
// import './App.css';
// import Home from './Component/Home/Home';
// import AOS from 'aos';
// import 'aos/dist/aos.css';



// function App() {

//   useEffect(() => {
//     AOS.init();
//     AOS.refresh();

//   }, [])

//   return (
//     <div className='App'>
//       <Home />
  
//     </div>
//   );
// }

// export default App;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Component/Home/Home';
import Contact from './Pages/contact'; // Adjust the import path
import Location from './Pages/location'; // Adjust the import path
import Products from './Pages/products'; // Adjust the import path
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/location" element={<Location/>} />
          <Route path="/products" element={<Products/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
