// Layout.js

import React from 'react';
import { Link } from 'react-router-dom';
import './Layout.css'; // Import layout-specific styles
import '../Navbar/Navbar.css'; // Import navbar styles
import '../Header/Header.css'; // Import header styles
import Header from '../Header/Header'; // Import Header component

const Layout = ({ children }) => {
  return (
    <div className="layout">
      <Header />
      <nav className="navbar-container">
        <div className="navbar-sub-container">
          <div className="navbar">
            <ul className="anim-nav">
              <li><Link to="/">Home</Link></li>
              <li><Link to="/location">Location</Link></li>
              <li><Link to="/products">Products</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </div>
        </div>
      </nav>
      <main className="content">
        {children}
      </main>
      <footer className="footer">
        {/* Footer content goes here */}
      </footer>
    </div>
  );
};

export default Layout;
