import emailjs from '@emailjs/browser';
import React, { useRef, useState } from 'react';
import Layout from '../Component/Layout/Layout';
import './contact.css'; // Import the Contact.css file

const Contact = () => {
  const form = useRef();
  const [notification, setNotification] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_xcur3ln', 'template_nkh9v8p', form.current, 'dTWHZTaHBsV29JQHb')
      .then(
        (result) => {
          console.log(result.text);
          setNotification('Your message has been sent successfully!');
          form.current.reset(); // Clear the form fields after successful submission
          setTimeout(() => setNotification(''), 6000); // Clear notification after 6 seconds
        },
        (error) => {
          console.log('FAILED...', error.text);
          setNotification('Failed to send your message. Please try again later.');
          setTimeout(() => setNotification(''), 6000); // Clear notification after 6 seconds
        }
      );
  };

  return (
    <Layout>
      <div className="contact-page">
        <div className="contact-container">
          <h2 className="contact-title">Contact Us</h2>
          <p className="contact-text">
            Do you have any questions or feedback? Feel free to fill out the form below, and we'll get back to you as soon as possible. 
            You can also use the phone numbers listed at the top of the page to reach us directly.
          </p>
          {notification && <div className={`notification ${notification.includes('successfully') ? 'success' : 'error'}`}>{notification}</div>}
          <form ref={form} onSubmit={sendEmail}>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input type="text" id="name" name="user_name" required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="user_email" required />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea id="message" name="message" rows="5" required></textarea>
            </div>
            <button type="submit" value="Send">Submit</button>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
